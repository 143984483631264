import type { IToast } from "~/components/VToasts/VToasts.types";
import { useAppStore } from "~/store/app";

export default () => {
  const { toasts } = storeToRefs(useAppStore());

  const success = (props: Partial<IToast>) => {
    const toast = {
      type: "success",
      key: Date.now(),
      text: "",
      ...props,
    };
    toasts.value.push(toast);
  };
  const warning = (props: Partial<IToast>) => {
    const toast = {
      type: "warning",
      key: Date.now(),
      text: "",
      ...props,
    };
    toasts.value.push(toast);
  };

  return {
    success,
    warning,
  };
};
